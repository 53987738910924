<template>
  <div :class="sidebarbg" class="app-sidebar sidebar-shadow" v-touch-swipe.mouse.left="handleSwipe">
    <div class="app-sidebar-content">
      <VuePerfectScrollbar class="app-sidebar-scroll left-sidebar-menu" >
        <div class="sidebar-top-bar">
          <div class="sidebar-top-bar-btngroup">
            {{$language('메뉴 바로가기')}}
          </div>
          <div class="sidebar-top-bar-close">
            <q-btn flat color="gray" icon="close" @click="toggleMobile('closed-sidebar-open')" />
          </div>
        </div>
        <!-- <c-sidebar-menu showOneChild :menu="permission_routers" /> -->
        <div class="sidebar-my-menu">
          <div class="sidebar-menu-all-title">{{$language('나만의 메뉴')}}<i class="material-icons sidebar-my-menu-edit" @click="menuSetting" v-if="bookmarks.length > 0">settings</i></div>
          <div class="no-bookmark" v-if="bookmarks.length == 0">{{$language('전체메뉴에서 나만의 메뉴를 지정하세요.')}}</div>
          <ul class="sidebar-my-ol" v-else>
            <li 
              class="sidebar-my-menu-item"
              v-for="(item, index) in bookmarks"
              :key="index"
              >
              <a class="shortcut_a" @click="goMobilePage(item.href)">
                <div class="sa_mw">
                  <i :class="['material-icons sidebar-my-menu-icon', getIcons(item.icon, 'color')]">{{ getIcons(item.icon, 'icon') }}</i>
                  <div class="sidebar-my-menu-title" v-html="item.title.replace(' ','<br/>') "></div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="sidebar-my-menu-all">
          <div class="sidebar-menu-all-title">{{$language('전체 메뉴')}}</div>
          <div 
            class="sidebar-my-menu-item-all"
            v-for="(item, index) in permission_routers"
            :key="index"
            >
              <div class="sidebar-my-menu-title-all-icon"><i :class="['material-icons sidebar-my-menu-icon-all', getIcons(item.icon, 'color')]">{{ getIcons(item.icon, 'icon') }}</i></div>
              <div class="sidebar-my-menu-title-all">
                <a class="shortcut_a" @click="goMobilePage(item.href)">{{ item.title }}
                </a>
                <q-badge class="abbr-menu" v-if="item.explanFlag === 'Y' && item.abbrMenuNm" color="blue-grey">
                  !  &nbsp; {{ item.abbrMenuNm }}
                </q-badge>
              </div>
              <div class="sidebar-my-menu-title-book"><i :class="['material-icons sidebar-my-menu-icon-book']" @click="mobileBookmark(item)">{{mobileBookmarkIcon(item)}}</i></div>
          </div>
        </div>
      </VuePerfectScrollbar>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import transactionConfig from '@/js/transactionConfig';
import { mapGetters } from 'vuex'
import mobileConfig from '@/js/mobileConfig';

export default {
  components: {
    VuePerfectScrollbar
  },
  props: {
    sidebarbg: String
  },
  data() {
    return {
      bookmarks: [],
      isOpen: false,
      sidebarActive: false,
      collapsed: true,
      windowWidth: 0,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '100%',
        isFull: true,
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      'permission_routers',
    ]),
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("resize", this.getWindowWidth);

      //Init
      this.getWindowWidth();
    });
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  },
  methods: {
    init() {
      this.getBookmarks();

      console.log(this.permission_routers)
    },
    menuSetting() {
      this.popupOptions.title = '나만의 메뉴 편집';
      this.popupOptions.target = () => import(`${'./SidebarEdit.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    handleSwipe() {
      this.toggleMobile('closed-sidebar-open')
    },
    getBookmarks() {
      this.$http.url = mobileConfig.sys.favorite.mobile.list.url;
      this.$http.type = 'GET';
      this.$http.isLoading = false;
      this.$http.param = {
        userId: this.$store.getters.user.userId,
        lang: this.$comm.getLang(),
      };
      this.$http.request((_result) => {
        this.bookmarks = _result.data;
      },);
    },
    getIcons(_icons, _type) {
      if (_type == 'icon') {
        return _icons.split('|')[0]
      } else {
        return _icons.length > 1 ? 'text-' + _icons.split('|')[1] : 'text-grey-10';
      }
    },
    toggleMobile(className) {
      const el = document.body;
      el.classList.remove(className);
    },
    // toggleSidebarHover(add, className) {
      // const el = document.body;
      // this.sidebarActive = !this.sidebarActive;

      // this.windowWidth = document.documentElement.clientWidth;

      // if (this.windowWidth > "992") {
      //   if (add === "add") {
      //     el.classList.add(className);
      //   } else {
      //     el.classList.remove(className);
      //   }
      // }
    // },
    getWindowWidth() {
      const el = document.body;

      this.windowWidth = document.documentElement.clientWidth;

      if (this.windowWidth < "3800") {
        el.classList.add("closed-sidebar", "closed-sidebar-md");
      }
    },
    logout() {   
      this.$http.url = transactionConfig.auth.login.logout.url;
      this.$http.type = 'POST';
      this.$http.request(
        this.$store.dispatch('LogOut').then(() => {
          window.sessionStorage.removeItem('defaultPlantCd');
          location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
        })
      );
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getBookmarks();
    },
    goMobilePage(url) {
      this.$router.push(url);
      this.toggleMobile('closed-sidebar-open')
    },
    mobileBookmarkIcon(item) {
      if (item.mobileFavoriteFlag == 'Y') {
        return 'bookmark';
      } else {
        return 'bookmark_border';
      }
    },
    mobileBookmark(item) {
      if (item.mobileFavoriteFlag == 'Y') {
        this.$http.url = mobileConfig.sys.favorite.mobile.insert.url;
        this.$http.type = 'PUT';
        this.$_.forEach(this.permission_routers, _item => {
          if (_item.name == item.name) {
            this.$set(_item, 'mobileFavoriteFlag', 'N')
          }
        })
      } else {
        this.$http.url = mobileConfig.sys.favorite.mobile.insert.url;
        this.$http.type = 'POST';
        this.$_.forEach(this.permission_routers, _item => {
          if (_item.name == item.name) {
            this.$set(_item, 'mobileFavoriteFlag', 'Y')
          }
        })
      }
      item.userId = this.$store.getters.user.userId;
      item.sysMenuId = item.name
      this.$http.param = item;
      this.$http.request(() => {
        this.getBookmarks();
      });
    }
  },
};
</script>
<style lang="sass">
.leftMenuUserArea
  // margin-bottom: 20px !important
  position: fixed
  bottom: 0
  left: 0
  right: 0
  height: 45px
  background: white
  // border-top: solid 2px #FFC107
  .logoutLink
    font-weight: 700
    font-size: 1.4em
    // display: block
    // line-height: 2.2rem
    // height: 2.2rem
    height: 100%
    // padding: 0.1rem 1.2rem 0 1.6rem
    padding: 0 !important
    position: relative
    border-radius: 0
    color: red !important
    white-space: nowrap
    -webkit-transition: all 0.2s
    transition: all 0.2s
    text-decoration: none
    text-align: center
    button
      border-radius: 0 !important

.close-menu
  position: fixed
  right: 20px
  top: 60px

.abbr-menu 
  width: 17px
  display: block !important
  transition: width 0.5s


.abbr-menu:hover  
  width: 100%

  
  
</style>

