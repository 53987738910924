/* eslint-disable */
import request from '@/utils/request';
import comm from '@/js/common';
import {
  krLanguageMap, // 한국어
  enLanguageMap, // 영어
  jaLanguageMap, // 일본어
  zhcnLanguageMap, // 중국어 (중국)
  zhtwLanguageMap, // 중국어 (대만)
} from '@/lang'
import {
  getLangInfo,
} from './login';
import _ from 'lodash'
import Cookies from 'js-cookie'

export function getLangLabelJson() {
  return request({
    url: comm.getLangLabelJsonApi(),
    method: 'GET',
  });
}
export function getLangMessageJson() {
  return request({
    url: comm.getLangMessageJsonApi(),
    method: 'GET',
  });
}
export function getLanguageJson() {
  /**
   * 권한 없는 언어 적용
   */
  return request({
    url: comm.getLanguageJsonApi(),
    method: 'GET',
  });
}

export function getLang() {
  return new Promise((resolve, reject) => {
    resolve({
      lbls: getLangLabelJson(),
      msgs: getLangMessageJson(),
      langs: getLanguageJson(),
    })
  });
}

const lang = {
  state: {
    lbls: [],
    msgs: [],
    langs: [],
  },
  mutations: {
    SET_LABEL: (state, data) => {
      if (!data.isConcat) {
        state.lbls = data.lbls
      } else {
        state.lbls = _.concat(state.lbls, data.lbls)
      }
    },
    SET_MESSAGE: (state, data) => {
      if (!data.isConcat) {
        state.msgs = data.msgs
      } else {
        state.msgs = _.concat(state.msgs, data.msgs)
      }
    },
    SET_LANGUAGE: (state, data) => {
      state.langs = data.langs
    },
    SET_LANG_INFO: (state, langInfo) => {
      state.langInfo = langInfo;
    },
  },
  actions: {
    SetLangJson({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        // 국가별 언어정보
        getLanguageJson()
          .then(_response => {
            let lang = Cookies.get('language') ? Cookies.get('language') : 'kr';
            let langs = [];
            switch(lang) {
              case 'kr':
                langs = krLanguageMap;
                break;
              case 'en':
                langs = enLanguageMap;
                break;
              case 'ja':
                langs = jaLanguageMap;
                break;
              case 'zh_cn':
                langs = zhcnLanguageMap;
                break;
              case 'zh_tw':
                langs = zhtwLanguageMap;
                break;
              default:
                langs = krLanguageMap;
            }
            if (_response.data && _response.data.length > 0) {
              langs = _.concat(langs, _response.data)
            }

            commit('SET_LANGUAGE', { langs: langs });
            resolve()
          })
          .catch(error => {
            reject(error);
          });
        // // 라벨정보
        // getLangLabelJson()
        //   .then(response => {
        //     commit('SET_LABEL', { lbls: response.data, isConcat: false });

        //     // 메시지 정보
        //     getLangMessageJson()
        //       .then(_response => {
        //         commit('SET_MESSAGE', { msgs: _response.data, isConcat: false });
        //         resolve()
        //       })
        //       .catch(error => {
        //         reject(error);
        //       });
        //   })
        //   .catch(error => {
        //     reject(error);
        //   });
      });
    },
    GetLangInfo({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        getLangInfo(data.lang, data.divide)
          .then(response => {
            const data = response.data;
            if (data) {
              let labels = data.label;
              let messages = data.message;
              if (labels) {
                if (lang.state.lbls && lang.state.lbls.length > 0) {
                  commit('SET_LABEL', 
                    { 
                      lbls: _.filter(labels, label => {
                        return _.findIndex(lang.state.lbls, { lblCd: label.lblCd }) === -1
                      }), 
                      isConcat: true
                    }
                  )
                } else {
                  commit('SET_LABEL', { lbls: labels, isConcat: false });
                }
              }
              if (messages) {
                if (lang.state.msgs && lang.state.msgs.length > 0) {
                  commit('SET_MESSAGE', 
                    { 
                      msgs: _.filter(messages, message => {
                        return _.findIndex(lang.state.msgs, { msgCd: message.msgCd }) === -1
                      }),
                      isConcat: true
                    }
                  )
                } else {
                  commit('SET_MESSAGE', { msgs: messages, isConcat: false });
                }
              }

              // commit('SET_LANG_INFO', data);
              // commit('SET_MESSAGE', data.message);
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  }
}

export default lang
