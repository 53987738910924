import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=6c89f27a"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
import style0 from "./Header.vue?vue&type=style&index=0&id=6c89f27a&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\PROJECT\\SHMS-DEMO\\PSMMobile\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6c89f27a')) {
      api.createRecord('6c89f27a', component.options)
    } else {
      api.reload('6c89f27a', component.options)
    }
    module.hot.accept("./Header.vue?vue&type=template&id=6c89f27a", function () {
      api.rerender('6c89f27a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Layout/Components/Header.vue"
export default component.exports