/**
 * 권한이 없는 페이지에서 사용할 언어팩
 */
// 한국어
export const krLanguageMap = [
  /** 로그인 페이지 */
  {
    conversionLanguage: 'Log In',
    languageKey: 'Log In',
  },
  {
    conversionLanguage: 'Sign In',
    languageKey: 'Sign In',
  },
  {
    conversionLanguage: '알림',
    languageKey: '알림',
  },
  {
    conversionLanguage: '에러',
    languageKey: '에러',
  },
  {
    conversionLanguage: '서버에 연결할 수 없습니다. 관리자에게 문의바랍니다.',
    languageKey: '서버에 연결할 수 없습니다. 관리자에게 문의바랍니다.',
  },
  {
    conversionLanguage: '로그인 에러',
    languageKey: '로그인 에러',
  },
  {
    conversionLanguage: '로그인 진행중 에러가 발생하였습니다. 관리자에게 문의바랍니다.',
    languageKey: '로그인 진행중 에러가 발생하였습니다. 관리자에게 문의바랍니다.',
  },
  {
    conversionLanguage: '확인',
    languageKey: '확인',
  },
  {
    conversionLanguage: '비밀번호 변경 안내',
    languageKey: '비밀번호 변경 안내',
  },
  {
    conversionLanguage: '다시 로그인 해주시기 바랍니다.',
    languageKey: '다시 로그인 해주시기 바랍니다.',
  },
  {
    conversionLanguage: '고객님의 비밀번호를',
    languageKey: '고객님의 비밀번호를',
  },
  {
    conversionLanguage: '변경',
    languageKey: '변경',
  },
  {
    conversionLanguage: '해 주세요.',
    languageKey: '해 주세요.',
  },
  {
    conversionLanguage: '아이디',
    languageKey: '아이디',
  },
  {
    conversionLanguage: '기존 비밀번호',
    languageKey: '기존 비밀번호',
  },
  {
    conversionLanguage: '변경 비밀번호',
    languageKey: '변경 비밀번호',
  },
  {
    conversionLanguage: '변경 비밀번호 확인',
    languageKey: '변경 비밀번호 확인',
  },
  {
    conversionLanguage: '다음에 변경하기',
    languageKey: '다음에 변경하기',
  },
  {
    conversionLanguage: '비밀번호 변경',
    languageKey: '비밀번호 변경',
  },
  {
    conversionLanguage: '✔ 고객님의 소중한 개인정보를 보호하기 위하여 비밀번호 변경을 안내해 드리고 있습니다.',
    languageKey: '✔ 고객님의 소중한 개인정보를 보호하기 위하여 비밀번호 변경을 안내해 드리고 있습니다.',
  },
  {
    conversionLanguage: '✔ 정기적인 비밀번호 변경으로 고객님의 개인정보를 보호해 주세요.',
    languageKey: '✔ 정기적인 비밀번호 변경으로 고객님의 개인정보를 보호해 주세요.',
  },
  {
    conversionLanguage: '❗❗ 비밀번호는 8자리 이상 소문자/대문자/숫자/특수문자 중 3개 이상 조합하여 작성하세요.',
    languageKey: '❗❗ 비밀번호는 8자리 이상 소문자/대문자/숫자/특수문자 중 3개 이상 조합하여 작성하세요.',
  },
  {
    conversionLanguage: '비밀번호를 입력하세요!',
    languageKey: '비밀번호를 입력하세요!',
  },
  {
    conversionLanguage: '변경비밀번호와 비밀번호 확인이 다릅니다.',
    languageKey: '변경비밀번호와 비밀번호 확인이 다릅니다.',
  },
  {
    conversionLanguage: '비밀번호는 최소 8자리여야 하며,\n 대문자/소문자/숫자/특주문자 중 3개를 포함해야합니다.',
    languageKey: '비밀번호는 최소 8자리여야 하며,\n 대문자/소문자/숫자/특주문자 중 3개를 포함해야합니다.',
  },
  {
    conversionLanguage: '변경하시겠습니까?',
    languageKey: '변경하시겠습니까?',
  },
  {
    conversionLanguage: '기존 비밀번호가 틀립니다.',
    languageKey: '기존 비밀번호가 틀립니다.',
  },
  {
    conversionLanguage: '다음에 변경하시겠습니까?',
    languageKey: '다음에 변경하시겠습니까?',
  },
  /** 404 페이지 */
  {
    conversionLanguage: '존재하지 않는 페이지입니다.',
    languageKey: '존재하지 않는 페이지입니다.',
  },
  {
    conversionLanguage: '메인화면으로 이동',
    languageKey: '메인화면으로 이동',
  },
  {
    conversionLanguage: '잘못된경로입니다.',
    languageKey: '잘못된경로입니다.',
  },
];
// 영어
export const enLanguageMap = [
  /** 로그인 페이지 */
  {
    conversionLanguage: 'Log In',
    languageKey: 'Log In',
  },
  {
    conversionLanguage: 'Sign In',
    languageKey: 'Sign In',
  },
  {
    conversionLanguage: 'Alert',
    languageKey: '알림',
  },
  {
    conversionLanguage: 'Error',
    languageKey: '에러',
  },
  {
    conversionLanguage: 'Unable to connect to server. Please contact the administrator.',
    languageKey: '서버에 연결할 수 없습니다. 관리자에게 문의바랍니다.',
  },
  {
    conversionLanguage: 'Login Error',
    languageKey: '로그인 에러',
  },
  {
    conversionLanguage: 'An error occurred while logging in. Please contact the administrator.',
    languageKey: '로그인 진행중 에러가 발생하였습니다. 관리자에게 문의바랍니다.',
  },
  {
    conversionLanguage: 'Confirm',
    languageKey: '확인',
  },
  {
    conversionLanguage: 'Password change information',
    languageKey: '비밀번호 변경 안내',
  },
  {
    conversionLanguage: 'Please log in again.',
    languageKey: '다시 로그인 해주시기 바랍니다.',
  },
  {
    conversionLanguage: 'your password',
    languageKey: '고객님의 비밀번호를',
  },
  {
    conversionLanguage: 'change',
    languageKey: '변경',
  },
  {
    conversionLanguage: 'Please do it.',
    languageKey: '해 주세요.',
  },
  {
    conversionLanguage: 'ID',
    languageKey: '아이디',
  },
  {
    conversionLanguage: 'existing password',
    languageKey: '기존 비밀번호',
  },
  {
    conversionLanguage: 'change password',
    languageKey: '변경 비밀번호',
  },
  {
    conversionLanguage: 'Confirm change password',
    languageKey: '변경 비밀번호 확인',
  },
  {
    conversionLanguage: 'Change next time',
    languageKey: '다음에 변경하기',
  },
  {
    conversionLanguage: 'Change password',
    languageKey: '비밀번호 변경',
  },
  {
    conversionLanguage: '✔ To protect your valuable personal information, we are informing you of changing your password.',
    languageKey: '✔ 고객님의 소중한 개인정보를 보호하기 위하여 비밀번호 변경을 안내해 드리고 있습니다.',
  },
  {
    conversionLanguage: '✔ Please protect your personal information by changing your password regularly.',
    languageKey: '✔ 정기적인 비밀번호 변경으로 고객님의 개인정보를 보호해 주세요.',
  },
  {
    conversionLanguage: '❗❗ Please write your password with at least 8 characters and a combination of at least 3 of the following: lowercase letters/uppercase letters/numbers/special characters.',
    languageKey: '❗❗ 비밀번호는 8자리 이상 소문자/대문자/숫자/특수문자 중 3개 이상 조합하여 작성하세요.',
  },
  {
    conversionLanguage: 'Please enter your password!',
    languageKey: '비밀번호를 입력하세요!',
  },
  {
    conversionLanguage: 'Change password and confirm password are different.',
    languageKey: '변경비밀번호와 비밀번호 확인이 다릅니다.',
  },
  {
    conversionLanguage: 'The password must be at least 8 characters long and contain 3 of the following characters: uppercase/lowercase/number/special character.',
    languageKey: '비밀번호는 최소 8자리여야 하며,\n 대문자/소문자/숫자/특주문자 중 3개를 포함해야합니다.',
  },
  {
    conversionLanguage: 'Do you want to change it?',
    languageKey: '변경하시겠습니까?',
  },
  {
    conversionLanguage: 'The existing password is incorrect.',
    languageKey: '기존 비밀번호가 틀립니다.',
  },
  {
    conversionLanguage: 'Would you like to change it next time?',
    languageKey: '다음에 변경하시겠습니까?',
  },
  /** 404 페이지 */
  {
    conversionLanguage: "This page doesn't exist.",
    languageKey: '존재하지 않는 페이지입니다.',
  },
  {
    conversionLanguage: 'Go to main screen',
    languageKey: '메인화면으로 이동',
  },
  {
    conversionLanguage: 'This is the wrong path.',
    languageKey: '잘못된경로입니다.',
  },
];
// 일본어
export const jaLanguageMap = [
  /** 로그인 페이지 */
  {
    conversionLanguage: 'Log In',
    languageKey: 'Log In',
  },
  {
    conversionLanguage: 'Sign In',
    languageKey: 'Sign In',
  },
  {
    conversionLanguage: '알림',
    languageKey: '알림',
  },
  {
    conversionLanguage: '에러',
    languageKey: '에러',
  },
  {
    conversionLanguage: '서버에 연결할 수 없습니다. 관리자에게 문의바랍니다.',
    languageKey: '서버에 연결할 수 없습니다. 관리자에게 문의바랍니다.',
  },
  {
    conversionLanguage: '로그인 에러',
    languageKey: '로그인 에러',
  },
  {
    conversionLanguage: '로그인 진행중 에러가 발생하였습니다. 관리자에게 문의바랍니다.',
    languageKey: '로그인 진행중 에러가 발생하였습니다. 관리자에게 문의바랍니다.',
  },
  {
    conversionLanguage: '확인',
    languageKey: '확인',
  },
  {
    conversionLanguage: '비밀번호 변경 안내',
    languageKey: '비밀번호 변경 안내',
  },
  {
    conversionLanguage: '다시 로그인 해주시기 바랍니다.',
    languageKey: '다시 로그인 해주시기 바랍니다.',
  },
  {
    conversionLanguage: '고객님의 비밀번호를',
    languageKey: '고객님의 비밀번호를',
  },
  {
    conversionLanguage: '변경',
    languageKey: '변경',
  },
  {
    conversionLanguage: '해 주세요.',
    languageKey: '해 주세요.',
  },
  {
    conversionLanguage: '아이디',
    languageKey: '아이디',
  },
  {
    conversionLanguage: '기존 비밀번호',
    languageKey: '기존 비밀번호',
  },
  {
    conversionLanguage: '변경 비밀번호',
    languageKey: '변경 비밀번호',
  },
  {
    conversionLanguage: '변경 비밀번호 확인',
    languageKey: '변경 비밀번호 확인',
  },
  {
    conversionLanguage: '다음에 변경하기',
    languageKey: '다음에 변경하기',
  },
  {
    conversionLanguage: '비밀번호 변경',
    languageKey: '비밀번호 변경',
  },
  {
    conversionLanguage: '✔ 고객님의 소중한 개인정보를 보호하기 위하여 비밀번호 변경을 안내해 드리고 있습니다.',
    languageKey: '✔ 고객님의 소중한 개인정보를 보호하기 위하여 비밀번호 변경을 안내해 드리고 있습니다.',
  },
  {
    conversionLanguage: '✔ 정기적인 비밀번호 변경으로 고객님의 개인정보를 보호해 주세요.',
    languageKey: '✔ 정기적인 비밀번호 변경으로 고객님의 개인정보를 보호해 주세요.',
  },
  {
    conversionLanguage: '❗❗ 비밀번호는 8자리 이상 소문자/대문자/숫자/특수문자 중 3개 이상 조합하여 작성하세요.',
    languageKey: '❗❗ 비밀번호는 8자리 이상 소문자/대문자/숫자/특수문자 중 3개 이상 조합하여 작성하세요.',
  },
  {
    conversionLanguage: '비밀번호를 입력하세요!',
    languageKey: '비밀번호를 입력하세요!',
  },
  {
    conversionLanguage: '변경비밀번호와 비밀번호 확인이 다릅니다.',
    languageKey: '변경비밀번호와 비밀번호 확인이 다릅니다.',
  },
  {
    conversionLanguage: '비밀번호는 최소 8자리여야 하며,\n 대문자/소문자/숫자/특주문자 중 3개를 포함해야합니다.',
    languageKey: '비밀번호는 최소 8자리여야 하며,\n 대문자/소문자/숫자/특주문자 중 3개를 포함해야합니다.',
  },
  {
    conversionLanguage: '변경하시겠습니까?',
    languageKey: '변경하시겠습니까?',
  },
  {
    conversionLanguage: '기존 비밀번호가 틀립니다.',
    languageKey: '기존 비밀번호가 틀립니다.',
  },
  {
    conversionLanguage: '다음에 변경하시겠습니까?',
    languageKey: '다음에 변경하시겠습니까?',
  },
  /** 404 페이지 */
  {
    conversionLanguage: '존재하지 않는 페이지입니다.',
    languageKey: '존재하지 않는 페이지입니다.',
  },
  {
    conversionLanguage: '메인화면으로 이동',
    languageKey: '메인화면으로 이동',
  },
  {
    conversionLanguage: '잘못된경로입니다.',
    languageKey: '잘못된경로입니다.',
  },
];
// 중국어
export const zhcnLanguageMap = [
  /** 로그인 페이지 */
  {
    conversionLanguage: 'Log In',
    languageKey: 'Log In',
  },
  {
    conversionLanguage: 'Sign In',
    languageKey: 'Sign In',
  },
  {
    conversionLanguage: '알림',
    languageKey: '알림',
  },
  {
    conversionLanguage: '에러',
    languageKey: '에러',
  },
  {
    conversionLanguage: '서버에 연결할 수 없습니다. 관리자에게 문의바랍니다.',
    languageKey: '서버에 연결할 수 없습니다. 관리자에게 문의바랍니다.',
  },
  {
    conversionLanguage: '로그인 에러',
    languageKey: '로그인 에러',
  },
  {
    conversionLanguage: '로그인 진행중 에러가 발생하였습니다. 관리자에게 문의바랍니다.',
    languageKey: '로그인 진행중 에러가 발생하였습니다. 관리자에게 문의바랍니다.',
  },
  {
    conversionLanguage: '확인',
    languageKey: '확인',
  },
  {
    conversionLanguage: '비밀번호 변경 안내',
    languageKey: '비밀번호 변경 안내',
  },
  {
    conversionLanguage: '다시 로그인 해주시기 바랍니다.',
    languageKey: '다시 로그인 해주시기 바랍니다.',
  },
  {
    conversionLanguage: '고객님의 비밀번호를',
    languageKey: '고객님의 비밀번호를',
  },
  {
    conversionLanguage: '변경',
    languageKey: '변경',
  },
  {
    conversionLanguage: '해 주세요.',
    languageKey: '해 주세요.',
  },
  {
    conversionLanguage: '아이디',
    languageKey: '아이디',
  },
  {
    conversionLanguage: '기존 비밀번호',
    languageKey: '기존 비밀번호',
  },
  {
    conversionLanguage: '변경 비밀번호',
    languageKey: '변경 비밀번호',
  },
  {
    conversionLanguage: '변경 비밀번호 확인',
    languageKey: '변경 비밀번호 확인',
  },
  {
    conversionLanguage: '다음에 변경하기',
    languageKey: '다음에 변경하기',
  },
  {
    conversionLanguage: '비밀번호 변경',
    languageKey: '비밀번호 변경',
  },
  {
    conversionLanguage: '✔ 고객님의 소중한 개인정보를 보호하기 위하여 비밀번호 변경을 안내해 드리고 있습니다.',
    languageKey: '✔ 고객님의 소중한 개인정보를 보호하기 위하여 비밀번호 변경을 안내해 드리고 있습니다.',
  },
  {
    conversionLanguage: '✔ 정기적인 비밀번호 변경으로 고객님의 개인정보를 보호해 주세요.',
    languageKey: '✔ 정기적인 비밀번호 변경으로 고객님의 개인정보를 보호해 주세요.',
  },
  {
    conversionLanguage: '❗❗ 비밀번호는 8자리 이상 소문자/대문자/숫자/특수문자 중 3개 이상 조합하여 작성하세요.',
    languageKey: '❗❗ 비밀번호는 8자리 이상 소문자/대문자/숫자/특수문자 중 3개 이상 조합하여 작성하세요.',
  },
  {
    conversionLanguage: '비밀번호를 입력하세요!',
    languageKey: '비밀번호를 입력하세요!',
  },
  {
    conversionLanguage: '변경비밀번호와 비밀번호 확인이 다릅니다.',
    languageKey: '변경비밀번호와 비밀번호 확인이 다릅니다.',
  },
  {
    conversionLanguage: '비밀번호는 최소 8자리여야 하며,\n 대문자/소문자/숫자/특주문자 중 3개를 포함해야합니다.',
    languageKey: '비밀번호는 최소 8자리여야 하며,\n 대문자/소문자/숫자/특주문자 중 3개를 포함해야합니다.',
  },
  {
    conversionLanguage: '변경하시겠습니까?',
    languageKey: '변경하시겠습니까?',
  },
  {
    conversionLanguage: '기존 비밀번호가 틀립니다.',
    languageKey: '기존 비밀번호가 틀립니다.',
  },
  {
    conversionLanguage: '다음에 변경하시겠습니까?',
    languageKey: '다음에 변경하시겠습니까?',
  },
  /** 404 페이지 */
  {
    conversionLanguage: '존재하지 않는 페이지입니다.',
    languageKey: '존재하지 않는 페이지입니다.',
  },
  {
    conversionLanguage: '메인화면으로 이동',
    languageKey: '메인화면으로 이동',
  },
  {
    conversionLanguage: '잘못된경로입니다.',
    languageKey: '잘못된경로입니다.',
  },
];
export const zhtwLanguageMap = [
  /** 로그인 페이지 */
  {
    conversionLanguage: 'Log In',
    languageKey: 'Log In',
  },
  {
    conversionLanguage: 'Sign In',
    languageKey: 'Sign In',
  },
  {
    conversionLanguage: '알림',
    languageKey: '알림',
  },
  {
    conversionLanguage: '에러',
    languageKey: '에러',
  },
  {
    conversionLanguage: '서버에 연결할 수 없습니다. 관리자에게 문의바랍니다.',
    languageKey: '서버에 연결할 수 없습니다. 관리자에게 문의바랍니다.',
  },
  {
    conversionLanguage: '로그인 에러',
    languageKey: '로그인 에러',
  },
  {
    conversionLanguage: '로그인 진행중 에러가 발생하였습니다. 관리자에게 문의바랍니다.',
    languageKey: '로그인 진행중 에러가 발생하였습니다. 관리자에게 문의바랍니다.',
  },
  {
    conversionLanguage: '확인',
    languageKey: '확인',
  },
  {
    conversionLanguage: '비밀번호 변경 안내',
    languageKey: '비밀번호 변경 안내',
  },
  {
    conversionLanguage: '다시 로그인 해주시기 바랍니다.',
    languageKey: '다시 로그인 해주시기 바랍니다.',
  },
  {
    conversionLanguage: '고객님의 비밀번호를',
    languageKey: '고객님의 비밀번호를',
  },
  {
    conversionLanguage: '변경',
    languageKey: '변경',
  },
  {
    conversionLanguage: '해 주세요.',
    languageKey: '해 주세요.',
  },
  {
    conversionLanguage: '아이디',
    languageKey: '아이디',
  },
  {
    conversionLanguage: '기존 비밀번호',
    languageKey: '기존 비밀번호',
  },
  {
    conversionLanguage: '변경 비밀번호',
    languageKey: '변경 비밀번호',
  },
  {
    conversionLanguage: '변경 비밀번호 확인',
    languageKey: '변경 비밀번호 확인',
  },
  {
    conversionLanguage: '다음에 변경하기',
    languageKey: '다음에 변경하기',
  },
  {
    conversionLanguage: '비밀번호 변경',
    languageKey: '비밀번호 변경',
  },
  {
    conversionLanguage: '✔ 고객님의 소중한 개인정보를 보호하기 위하여 비밀번호 변경을 안내해 드리고 있습니다.',
    languageKey: '✔ 고객님의 소중한 개인정보를 보호하기 위하여 비밀번호 변경을 안내해 드리고 있습니다.',
  },
  {
    conversionLanguage: '✔ 정기적인 비밀번호 변경으로 고객님의 개인정보를 보호해 주세요.',
    languageKey: '✔ 정기적인 비밀번호 변경으로 고객님의 개인정보를 보호해 주세요.',
  },
  {
    conversionLanguage: '❗❗ 비밀번호는 8자리 이상 소문자/대문자/숫자/특수문자 중 3개 이상 조합하여 작성하세요.',
    languageKey: '❗❗ 비밀번호는 8자리 이상 소문자/대문자/숫자/특수문자 중 3개 이상 조합하여 작성하세요.',
  },
  {
    conversionLanguage: '비밀번호를 입력하세요!',
    languageKey: '비밀번호를 입력하세요!',
  },
  {
    conversionLanguage: '변경비밀번호와 비밀번호 확인이 다릅니다.',
    languageKey: '변경비밀번호와 비밀번호 확인이 다릅니다.',
  },
  {
    conversionLanguage: '비밀번호는 최소 8자리여야 하며,\n 대문자/소문자/숫자/특주문자 중 3개를 포함해야합니다.',
    languageKey: '비밀번호는 최소 8자리여야 하며,\n 대문자/소문자/숫자/특주문자 중 3개를 포함해야합니다.',
  },
  {
    conversionLanguage: '변경하시겠습니까?',
    languageKey: '변경하시겠습니까?',
  },
  {
    conversionLanguage: '기존 비밀번호가 틀립니다.',
    languageKey: '기존 비밀번호가 틀립니다.',
  },
  {
    conversionLanguage: '다음에 변경하시겠습니까?',
    languageKey: '다음에 변경하시겠습니까?',
  },
  /** 404 페이지 */
  {
    conversionLanguage: '존재하지 않는 페이지입니다.',
    languageKey: '존재하지 않는 페이지입니다.',
  },
  {
    conversionLanguage: '메인화면으로 이동',
    languageKey: '메인화면으로 이동',
  },
  {
    conversionLanguage: '잘못된경로입니다.',
    languageKey: '잘못된경로입니다.',
  },
];

export default krLanguageMap